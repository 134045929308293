<template>
<div>
  <el-card>
    <el-alert
        title="关于我们"
        type="success"
        effect="dark" :closable="false">
    </el-alert>

     <div style="padding: 20px" class="box">
       <p class="p">我们是一个致力于为游戏玩家提供高品质游戏娱乐体验的在线平台。我们的目标是在这里为所有类型的玩家提供最好的服务。</p>

       <h3 class="title">我们的使命</h3>

       <p class="p">我们的使命是为广大游戏玩家提供一个便捷、丰富的游戏平台，让玩家们有机会与其他玩家进行交流和互动。我们坚信，只要努力不懈地工作并持续改进我们的产品和服务，我们可以为每位玩家创造出独特而令人难忘的游戏经历。</p>

       <h3 class="title">我们的团队</h3>

       <p class="p">我们的团队由一群拥有丰富游戏行业经验和技能的专业人员组成。无论是设计、开发还是客户支持，我们都严格把控，以确保我们的玩家得到最好的体验。我们深知，优秀的游戏平台离不开强大的团队配合，我们也会一如既往地投入到这个工作中。</p>

       <h3 class="title">联系我们</h3>

       <p class="p">如果你有任何问题、建议或者想法，请随时向我们反馈，我们的邮箱是xxx@xxx.com。同时，我们也欢迎来关注我们的社交媒体账号以获取最新的消息和活动。</p>
     </div>
  </el-card>
</div>
</template>

<script>
export default {
  name: "indexPage"
}
</script>

<style scoped>
.box {
  .title {
    margin: 20px 0;
    font-size: 24px;
  }
  .p {
    font-size: 18px;
  }
}
</style>
