import axios from "axios"; //引入
import { Message } from "element-ui"; // 导入自己要用的ui组件库

let baseURL = "http://localhost:3000/";

//这一步的目的是判断出当前是开发环境还是生成环境，方法不止一种，达到目的就行
// if(process.env.NODE_ENV=="development"){
//   baseURL=''
// }else{
//   baseURL=''
// }

let config = {
    baseURL: baseURL,
    timeout: 30000, //设置最大请求时间
};
const _axios = axios.create(config);

/* 请求拦截器（请求之前的操作） */
_axios.interceptors.request.use(
    (config) => {
        return config;
    },
    (err) => Promise.reject(err)
);

/* 请求之后的操作 */
_axios.interceptors.response.use(
    (res) => {
        //关闭请求时的loading动
        //返回401无权限，可能会是跳回到登录页的操作，结合项目写
        if (res.data.code == 500) {
            console.log('6')
            Message.error(res.data.message);
        } else {
            return res.data
        }
    },
    (err) => {
        if (err) {
            //在这里关闭请求时的loading动画效果
            Message.error("请求网络失败");
        }
        return Promise.reject(err);
    }
);


// methods可变的请求方式
const request = (methods, url = "", params = {}) => {
    let paramsRequest = {
        url,
        headers: { "Content-Type": "application/json;charset=UTF-8" },
        method: methods,
    };
    // get请求使用params
    if (methods === "get" || methods === "GET") {
        paramsRequest.params = params;
    } else {
        // post put 请求使用data
        paramsRequest.data = params;
    }
    return new Promise((resolve) => {

        _axios(paramsRequest)
            .then((res) => {
                console.log('o', res)
                if (res.code == 500) {
                    Message.error(res.data.message)
                } else {
                    resolve(res);
                    return res;
                }

            })
            .catch(() => {
                // Message.error(error)
                // reject(error);
            });
    });
};
export default request;
