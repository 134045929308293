import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Welcome from '../views/Welcome.vue'
// import {Message} from "element-ui";
// import user from "@/views/user/index.vue";
import gameType from "@/views/gameType/index.vue";
import Game from "@/views/game/index.vue";
import about from "@/views/about/index.vue";
import whitelist from "@/views/whitelist/index.vue";
import walletRecharge from "@/views/financialManagement/walletRecharge/index.vue";
import rechargeDetail from "@/views/financialManagement/rechargeDetail/index.vue";
import balanceConversion from "@/views/financialManagement/balanceConversion/index.vue";
import conversionDetails from "@/views/financialManagement/conversionDetails/index.vue";
import playerList from "@/views/gameManagement/playerList/index.vue";
import transferRecord from "@/views/gameManagement/transferRecord/index.vue";
import gameRecord from "@/views/gameManagement/gameRecord/index.vue";
import bettingStatistics from "@/views/dataStatistics/bettingStatistics/index.vue";
import feedbackManagement from "@/views/feedbackManagement/feedbackManagement/index.vue";
import editPassword from "@/views/system/editPassword/index.vue";

Vue.use(VueRouter)

const routes = [
  { path: '/', redirect: '/login' },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue')
  },
  {
    path: '/home',
    component: Home,
    redirect: '/welcome',
    children: [
      { path: '/welcome', component: Welcome },
      { path: '/whitelist', component: whitelist },
      { path: '/walletRecharge', component: walletRecharge },
      { path: '/rechargeDetail', component: rechargeDetail },
      { path: '/balanceConversion', component: balanceConversion },
      { path: '/conversionDetails', component: conversionDetails },
      { path: '/playerList', component: playerList },
      { path: '/transferRecord', component: transferRecord },
      { path: '/gameRecord', component: gameRecord },
      { path: '/bettingStatistics', component: bettingStatistics },
      { path: '/feedbackManagement', component: feedbackManagement },
      { path: '/editPassword', component: editPassword },
      { path: '/gameType', component: gameType },
      { path: '/game', component: Game },
      { path: '/about', component: about },
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// 挂载路由导航守卫
// router.beforeEach((to, from, next) => {
//   if (to.path === '/login') return next()
//   // 获取token
//   const userInfo = JSON.parse(window.sessionStorage.getItem('userInfo'))
//   if (!userInfo) {
//     Message.error('尚未登陆，请先登录！')
//     return next('/login')
//   }
//   next()
// })

export default router
