let MenuList = [
    {
        id: 1,
        authName: '首页',
        path: '/welcome'
    },
    {
        id: 2,
        authName: '白名单管理',
        path: '/whitelist'
    },
    {
        id: 3,
        authName: '财务管理',
        children: [
            {
                id: 31,
                authName: '钱包充值',
                path: '/walletRecharge'
            },
            {
                id: 32,
                authName: '充值明细',
                path: '/rechargeDetail'
            },
            {
                id: 33,
                authName: '余额转换',
                path: '/balanceConversion'
            },
            {
                id: 34,
                authName: '转换明细',
                path: '/conversionDetails'
            },
        ]
    },
    {
        id: 4,
        authName: '游戏管理',
        children: [
            {
                id: 41,
                authName: '玩家列表',
                path: '/playerList'
            },
            {
                id: 42,
                authName: '转账记录',
                path: '/transferRecord'
            },
            {
                id: 43,
                authName: '游戏记录',
                path: '/gameRecord'
            }
        ]
    },
    {
        id: 5,
        authName: '数据统计',
        children: [
            {
                id: 51,
                authName: '投注统计',
                path: '/bettingStatistics'
            }
        ]
    },
    {
        id: 6,
        authName: '反馈管理',
        children: [
            {
                id: 61,
                authName: '反馈管理',
                path: '/feedbackManagement'
            }
        ]
    },
    {
        id: 7,
        authName: '系统管理',
        children: [
            {
                id: 71,
                authName: '修改密码',
                path: '/editPassword'
            }
        ]
    }
]

export default MenuList
