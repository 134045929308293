<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>财务管理</el-breadcrumb-item>
      <el-breadcrumb-item>钱包充值</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="app-container home" style="height: 300px;">
      <el-steps direction="vertical" :active="active">
        <el-step title="充值账户xxx">
          <template #description>
            <div>
              <div class="czBox">
                <span>充值金额：</span>
                <el-input v-model="money" style="width: 200px; margin-right: 10px;"></el-input>
                <span>快捷选项：</span>
                <el-radio-group v-model="radio" @input="radioChange">
                  <el-radio :label="1">1000</el-radio>
                  <el-radio :label="2">2000</el-radio>
                  <el-radio :label="3">3000</el-radio>
                  <el-radio :label="5">5000</el-radio>
                  <el-radio :label="10">10000</el-radio>
                </el-radio-group>
              </div>
              <div class="czInfo">
                每笔手续费 <span style="color: red">2%</span>,
                充值金额 <span style="color: red">{{ money }}</span>,
                手续费 <span style="color: red">{{ serviceFee.toFixed(2) }}</span>，
                实付金额 <span style="color: red">{{ totalAmount.toFixed(2) }}</span>
              </div>
            </div>
          </template>
        </el-step>
        <el-step title="选择充值渠道">
          <template #description>
            <div style="padding: 40px 0">
              <div class="deposit-way" @click="nextStep(2)" :style="{'borderColor': active == 2 ? 'red' : 'rgb(204, 204, 204)'}">
                <div style="display: flex; align-items: center">
                  <img src="@/assets/bank_icon.png" style="width: 14px; height: 14px;" alt="">
                  <span style="margin-left: 6px; color: rgb(51,51,51)">在线USDT</span>
                </div>
                <div class="bottom">
                  单笔100.00 | 最高50000.00
                </div>
              </div>
              <template v-if="active == 2">
                <div class="deposit-way-text">
                  <div>
                    <span>类型：</span>
                    <span class="right">TRC20</span>
                  </div>
                  <div>
                    <span>注意：</span>
                    <span class="right">显示多少USDT就转多少USDT,否则不会自动到账</span>
                  </div>
                  <div>
                    <span>收款地址：</span>
                    <span class="right">转账时，请确认收款地址：TM2RYhLEHw81FUG4hu6Mi3yTtUmWnYhVgs</span>
                  </div>
                </div>
                <div style="margin-top: 15px;">
                  <el-button type="primary" size="medium" style="width: 100px;" @click="nextStep(3)">
                    提交
                  </el-button>
                </div>
              </template>
            </div>
          </template>
        </el-step>
        <el-step title="充值成功"></el-step>
      </el-steps>
    </div>
  </div>
</template>

<script>
export default {
  name: "whitePage",
  data () {
    return {
      active: 1,
      money: 3000,
      radio: 3,
      feePercentage: 0.02,
    }
  },
  computed: {
    serviceFee: function() {
      return this.money * this.feePercentage;
    },
    totalAmount: function() {
      return this.money + this.serviceFee;
    }
  },
  methods: {
    radioChange (e) {
      console.log('eee', e)
      if (e == 1) {
        this.money = 1000
      } else if (e == 2) {
        this.money = 2000
      } else if (e == 3) {
        this.money = 3000
      } else if (e == 5) {
        this.money = 5000
      } else if (e == 10) {
        this.money = 10000
      }
    },
    nextStep (num) {
      this.active = num
    }
  }
}
</script>

<style scoped lang="less">
.app-container {
  .czBox {
    display: flex;
    align-items: center;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    margin-top: 30px;
  }
  .czInfo {
    font-weight: bold;
    font-size: 14px;
    color: black;
    margin: 10px 0px 40px;
  }
  .deposit-way {
    border: 1px solid;
    padding: 12px;
    border-radius: 6px;
    margin-right: 12px;
    width: 189px;
    color: rgba(0,0,0,.65);
    border-color: rgb(204, 204, 204);
    cursor: pointer;
    .bottom {
      display: flex;
      align-items: center;
      margin-top: 6px;
    }
  }
  .deposit-way-text {
    display: flex;
    flex-direction: column;
    color: rgba(0, 0, 0, 0.65);
    font-weight: bold;
    margin-top: 20px;
    .right {
      font-weight: 700;
      color: red;
    }
  }
}
</style>
