<template>
  <div>
    <!-- 面包屑导航区域 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>数据统计</el-breadcrumb-item>
      <el-breadcrumb-item>投注统计</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 卡片视图区域 -->
    <el-card>
      <!-- 搜索与添加区域 -->
      <el-row :gutter="20">
        <el-form :inline="true" :model="queryInfo" size="small" class="demo-form-inline">
          <el-form-item label="注单编号">
            <el-radio-group v-model="queryInfo.bh">
              <el-radio-button label="0">投注时间</el-radio-button>
              <el-radio-button label="1">结算时间</el-radio-button>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="">
            <el-radio-group v-model="queryInfo.timeVal">
              <el-radio-button label="0">日</el-radio-button>
              <el-radio-button label="1">月</el-radio-button>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="">
            <el-date-picker
                style="width: 240px;"
                v-model="queryInfo.dateTime"
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="游戏编码">
            <el-input v-model="queryInfo.name" placeholder="请输入内容"></el-input>
          </el-form-item>
          <el-form-item label="游戏账号">
            <el-input v-model="queryInfo.yxaccount" placeholder="请输入内容"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" icon="el-icon-search" @click="getUserList">搜索</el-button>
            <el-button @click="getUserList" icon="el-icon-refresh">重置</el-button>
          </el-form-item>
        </el-form>
      </el-row>

      <!-- 用户列表区域 -->
      <el-table :data="userlist" border stripe>
        <el-table-column label="商户名称" prop="username"></el-table-column>
        <el-table-column label="游戏账号" prop="qq"></el-table-column>
        <el-table-column label="下注金额" prop="qq"></el-table-column>
        <el-table-column label="净输赢" prop="email"></el-table-column>
        <el-table-column label="日期" prop="email"></el-table-column>
      </el-table>

      <div style=" display: flex; justify-content: space-between; align-items: center;">
        <div style="font-size: 14px">
          <span>用户数：</span>
          <span>0人</span>
          <span style="margin-left: 24px;">总下注金额：</span>
          <span>0</span>
          <span style="margin-left: 24px;">净输赢：</span>
          <span>0</span>
        </div>
        <!-- 分页区域 -->
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="queryInfo.pageNum" :page-sizes="[1, 2, 5, 10]" :page-size="queryInfo.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
      </div>


    </el-card>

  </div>
</template>

<script>
export default {
  name: 'useraPage',
  data() {
    return {
      editForm: {},
      dialogList: [],
      typeList: [
        { label: '转入', value: 0 },
        { label: '转出', value: 1 },
      ],
      statusList: [
        { label: '真人', value: 0 },
        { label: '捕鱼', value: 1 },
        { label: '电子', value: 2 },
        { label: '彩票', value: 3 },
        { label: '体育', value: 4 },
        { label: '棋牌', value: 5 },
        { label: '竞技', value: 6 },
      ],
      // 获取用户列表的参数对象
      queryInfo: {
        bh: '0',
        timeVal: '0',
        // 当前的页数
        pageNum: 1,
        // 当前每页显示多少条数据
        pageSize: 10
      },
      userlist: [],
      total: 0,
      editDialogVisible: false,
    }
  },
  mounted() {
    // this.getUserList()
  },
  methods: {
    async getUserList() {
      const res = await this.$apiFun.userList(this.queryInfo)
      this.userlist = res.data
      this.total = res.total
      console.log(res, 'dataList')
    },
    // 监听 pageSize 改变的事件
    handleSizeChange(newSize) {
      // console.log(newSize)
      this.queryInfo.pageSize = newSize
      this.getUserList()
    },
    // 监听 页码值 改变的事件
    handleCurrentChange(newPage) {
      console.log(newPage)
      this.queryInfo.pageNum = newPage
      this.getUserList()
    },
  }
}
</script>

<style lang="less" scoped>
</style>
