<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>财务管理</el-breadcrumb-item>
      <el-breadcrumb-item>余额转换</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="app-container home">
      <div style="margin-top: 30px;">
        <el-steps :active="active" align-center>
          <el-step title="余额转换" style="flex-basis: 33.3%">
          </el-step>
          <el-step title="确认转入金额" style="flex-basis: 33.3%">
          </el-step>
          <el-step title="完成" style="flex-basis: 33.3%"></el-step>
        </el-steps>

        <div class="step-one" v-if="active == 1">
          <el-row style="margin-bottom: 40px;">
            <el-col :span="8">
              <div style="display: flex; justify-content: flex-end;">
                钱包余额：
              </div>
            </el-col>
            <el-col :span="16">0元</el-col>
          </el-row>
          <el-row style="margin-bottom: 40px;">
            <el-col :span="8">
              <div style="display: flex; justify-content: flex-end;">
                转入钱包：
              </div>
            </el-col>
            <el-col :span="16">通用余额</el-col>
          </el-row>
          <el-row style="margin-bottom: 40px;">
            <el-col :span="8">
              <div style="display: flex; justify-content: flex-end;">
                转入金额：
              </div>
            </el-col>
            <el-col :span="16">
              <el-radio-group v-model="radio">
                <el-radio :label="5000">5000</el-radio>
                <el-radio :label="10000">10000</el-radio>
              </el-radio-group>
            </el-col>
          </el-row>
          <el-row style="margin-bottom: 40px;">
            <el-col :span="8">
              <div  style="display: flex; justify-content: flex-end; width: 100%; height: 10px;"> </div>
            </el-col>
            <el-col :span="16">
              <el-button type="primary" @click="submit">提交订单</el-button>
            </el-col>
          </el-row>
        </div>

        <div class="czsm">
          <span style="margin-left: 30px;">余额转换说明</span>
          <el-divider></el-divider>
          <div style="margin-left: 30px;">
            <span>温馨提示：</span>
            为方便商户测试，测试金额仅限转换一次，请小额测试，此金额可以正常使用，转换过后将不再显示
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "whitePage",
  data () {
    return {
      active: 1,
      radio: ''
    }
  },
  methods: {
    nextStep (num) {
      this.active = num
    },
    submit () {
      this.$message.error('您的余额不足,请先充值')
    }
  }
}
</script>

<style scoped lang="less">
.app-container {
  .step-one {
    margin-top: 40px;
    font-size: 14px;
  }
  .czsm {
    margin-top: 90px;
    span {
      font-size: 18px;
    }
    p {
      font-size: 12px;
    }
  }
}
</style>
