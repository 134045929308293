<template>
  <div>
    <!-- 面包屑导航区域 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>反馈管理</el-breadcrumb-item>
      <el-breadcrumb-item>反馈管理</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 卡片视图区域 -->
    <el-card>
      <!-- 搜索与添加区域 -->
      <el-row :gutter="20">
        <el-form :inline="true" :model="queryInfo" size="small" class="demo-form-inline">
          <el-form-item label="反馈类型">
            <el-select v-model="queryInfo.status" placeholder="请选择">
              <el-option
                  v-for="item in typeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="反馈用户">
            <el-input v-model="queryInfo.account" placeholder="请输入内容"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" icon="el-icon-search" @click="getUserList">搜索</el-button>
            <el-button @click="getUserList" icon="el-icon-refresh">重置</el-button>
          </el-form-item>
        </el-form>
      </el-row>

      <el-button size="mini" type="primary" icon="el-icon-plus" plain @click="addDialogVisible = true">新增</el-button>

      <!-- 用户列表区域 -->
      <el-table :data="userlist" border stripe>
        <el-table-column type="selection"></el-table-column>
        <el-table-column label="反馈账号" prop="account"></el-table-column>
        <el-table-column label="反馈类型" prop="username"></el-table-column>
        <el-table-column label="反馈内容" prop="qq"></el-table-column>
        <el-table-column label="反馈时间" prop="qq"></el-table-column>
        <el-table-column label="回复内容" prop="email"></el-table-column>
        <el-table-column label="回复时间" prop="email"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button type="text" icon="el-icon-edit" size="mini" @click="showEditDialog(scope.row)">修改</el-button>
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页区域 -->
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="queryInfo.pageNum" :page-sizes="[1, 2, 5, 10]" :page-size="queryInfo.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total">
      </el-pagination>
    </el-card>

    <el-dialog title="创建反馈" :visible.sync="addDialogVisible" width="600px" @close="addDialogClosed">
      <!-- 内容主体区域 -->
      <el-form :model="addForm" :rules="addFormRules" ref="addFormRef" label-width="80px">
        <el-form-item label="反馈类型" prop="type">
          <el-select v-model="addForm.type" placeholder="请选择">
            <el-option
                v-for="item in typeList"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="反馈内容" prop="content">
          <el-input v-model="addForm.content" type="textarea" :rows="8"></el-input>
        </el-form-item>
      </el-form>
      <!-- 底部区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="add">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="修改" :visible.sync="editDialogVisible" width="50%" @close="editDialogClosed">
      <el-form :model="editForm" :rules="addFormRules" ref="editFormRef" label-width="80px">
        <el-form-item label="反馈类型" prop="type">
          <el-select v-model="editForm.type" placeholder="请选择">
            <el-option
                v-for="item in typeList"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="反馈内容" prop="content">
          <el-input v-model="editForm.content" type="textarea" :rows="8"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="edit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'useraPage',
  data() {
    return {
      addForm: {},
      addFormRules: {
        type: [
          { required: true, message: '请选择反馈类型', trigger: 'change' },
        ],
        content: [
          { required: true, message: '请输入反馈内容', trigger: 'input' },
        ],
      },
      addDialogVisible: false,
      editForm: {},
      dialogList: [],
      typeList: [
        { label: '上分问题', value: 0 },
        { label: '其他问题', value: 1 },
        { label: '游戏问题', value: 2 },
      ],
      // 获取用户列表的参数对象
      queryInfo: {
        // 当前的页数
        pageNum: 1,
        // 当前每页显示多少条数据
        pageSize: 10
      },
      userlist: [],
      total: 0,
      editDialogVisible: false,
    }
  },
  mounted() {
    // this.getUserList()
  },
  methods: {
    async getUserList() {
      const res = await this.$apiFun.userList(this.queryInfo)
      this.userlist = res.data
      this.total = res.total
      console.log(res, 'dataList')
    },
    // 监听 pageSize 改变的事件
    handleSizeChange(newSize) {
      // console.log(newSize)
      this.queryInfo.pageSize = newSize
      this.getUserList()
    },
    // 监听 页码值 改变的事件
    handleCurrentChange(newPage) {
      console.log(newPage)
      this.queryInfo.pageNum = newPage
      this.getUserList()
    },
    async showEditDialog(row) {
      this.editForm = {...row}
      this.editDialogVisible = true
    },
    editDialogClosed() {
      this.$refs.editFormRef.resetFields()
    },
    addDialogClosed() {
      this.$refs.addFormRef.resetFields()
    },
    add () {

    },
    edit () {

    }
  }
}
</script>

<style lang="less" scoped>
</style>
