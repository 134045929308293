<template>
  <div class="app-container home">
    <el-row>
      <el-col :span="24">
        <el-alert
            :closable="false"
            effect="dark"
            title="Hi,欢迎使用CMS管理系统"
            style="background-color: #4b9bff;"
            description="换IP登录本站必须在此处授权最新IP，否则会跳至百度网站，建议使用服务器固定IP登录本站，开发文档"></el-alert>
      </el-col>
      <el-col :span="24" style="margin-top: 12px;">
        <el-alert
            :closable="false"
            type="error"
            title="商家首次使用，请先在“白名单管理”中添加api白名单。"
        ></el-alert>
      </el-col>
    </el-row>

    <el-row style="margin-top: 30px;">
      <el-col :span="12" style="padding-right: 10px;">
        <el-card>
          <div slot="header" class="clearfix">
            <span>账号信息（如密钥泄露请及时联系客服重置）</span>
            <i class="el-icon-refresh"></i>
          </div>
          <div class="text item">
            <div class="account-info">
              <div class="item">登录账号：jusL1</div>
              <div class="item">账户余额：0.00</div>
              <div class="item">账户类型：通用额度账户</div>
              <div class="item">通用余额(RMB)：65.50</div>
              <div class="item">KEY：603dcb002f624c96906a34cafa114b50</div>
              <div class="item" style="justify-content: space-between; display: flex">
                <span>最近一次登录IP: 43.242.201.189</span>
                <span>登录日期: 2024-07-25 16:06:51</span>
              </div>
            </div>
          </div>
        </el-card>
      </el-col>

      <el-col :span="12" style="padding-left: 10px;">
        <el-card>
          <div slot="header" class="clearfix">
            <span>公告</span>
          </div>
          <div class="text item">
            <el-collapse accordion>
              <el-collapse-item>
                <template slot="title">
                  <div class="noticeHeader">
                    <div class="noticeHeaderLeft">商户后台、API域名，对接文档【置顶】</div>
                    <div class="noticeHeaderRight">2023-10-24 10:01:17</div>
                  </div>
                </template>
              </el-collapse-item>
              <el-collapse-item>
                <template slot="title">
                  <div class="noticeHeader">
                    <div class="noticeHeaderLeft">商户后台、API域名，对接文档【置顶】</div>
                    <div class="noticeHeaderRight">2023-10-24 10:01:17</div>
                  </div>
                </template>
              </el-collapse-item>
              <el-collapse-item>
                <template slot="title">
                  <div class="noticeHeader">
                    <div class="noticeHeaderLeft">商户后台、API域名，对接文档【置顶】</div>
                    <div class="noticeHeaderRight">2023-10-24 10:01:17</div>
                  </div>
                </template>
              </el-collapse-item>
              <el-collapse-item>
                <template slot="title">
                  <div class="noticeHeader">
                    <div class="noticeHeaderLeft">商户后台、API域名，对接文档【置顶】</div>
                    <div class="noticeHeaderRight">2023-10-24 10:01:17</div>
                  </div>
                </template>
              </el-collapse-item>
              <el-collapse-item>
                <template slot="title">
                  <div class="noticeHeader">
                    <div class="noticeHeaderLeft">商户后台、API域名，对接文档【置顶】</div>
                    <div class="noticeHeaderRight">2023-10-24 10:01:17</div>
                  </div>
                </template>
              </el-collapse-item>
            </el-collapse>
            <el-pagination
                style="display: flex;justify-content: end;"
                background
                layout="prev, pager, next"
                :total="1000">
            </el-pagination>
          </div>
        </el-card>
      </el-col>
    </el-row>

    <div style="display: flex; justify-content: end">
      <el-button type="primary">刷新游戏分</el-button>
    </div>

    <div class="games">
      <div class="item">
        <div>CQ9电子</div>
        <div>CQ9(9)%</div>
        <div style="color: red">0</div>
      </div>
      <div class="item">
        <div>CQ9电子</div>
        <div>CQ9(9)%</div>
        <div style="color: red">0</div>
      </div>
      <div class="item">
        <div>CQ9电子</div>
        <div>CQ9(9)%</div>
        <div style="color: red">0</div>
      </div>
      <div class="item">
        <div>CQ9电子</div>
        <div>CQ9(9)%</div>
        <div style="color: red">0</div>
      </div>
      <div class="item">
        <div>CQ9电子</div>
        <div>CQ9(9)%</div>
        <div style="color: red">0</div>
      </div>
      <div class="item">
        <div>CQ9电子</div>
        <div>CQ9(9)%</div>
        <div style="color: red">0</div>
      </div>
      <div class="item">
        <div>CQ9电子</div>
        <div>CQ9(9)%</div>
        <div style="color: red">0</div>
      </div>
      <div class="item">
        <div>CQ9电子</div>
        <div>CQ9(9)%</div>
        <div style="color: red">0</div>
      </div>
      <div class="item">
        <div>CQ9电子</div>
        <div>CQ9(9)%</div>
        <div style="color: red">0</div>
      </div>
      <div class="item">
        <div>CQ9电子</div>
        <div>CQ9(9)%</div>
        <div style="color: red">0</div>
      </div>
      <div class="item">
        <div>CQ9电子</div>
        <div>CQ9(9)%</div>
        <div style="color: red">0</div>
      </div>
      <div class="item">
        <div>CQ9电子</div>
        <div>CQ9(9)%</div>
        <div style="color: red">0</div>
      </div>
      <div class="item">
        <div>CQ9电子</div>
        <div>CQ9(9)%</div>
        <div style="color: red">0</div>
      </div>
      <div class="item">
        <div>CQ9电子</div>
        <div>CQ9(9)%</div>
        <div style="color: red">0</div>
      </div>
      <div class="item">
        <div>CQ9电子</div>
        <div>CQ9(9)%</div>
        <div style="color: red">0</div>
      </div>
      <div class="item">
        <div>CQ9电子</div>
        <div>CQ9(9)%</div>
        <div style="color: red">0</div>
      </div>
      <div class="item">
        <div>CQ9电子</div>
        <div>CQ9(9)%</div>
        <div style="color: red">0</div>
      </div>
      <div class="item">
        <div>CQ9电子</div>
        <div>CQ9(9)%</div>
        <div style="color: red">0</div>
      </div>
      <div class="item">
        <div>CQ9电子</div>
        <div>CQ9(9)%</div>
        <div style="color: red">0</div>
      </div>
      <div class="item">
        <div>CQ9电子</div>
        <div>CQ9(9)%</div>
        <div style="color: red">0</div>
      </div>
      <div class="item">
        <div>CQ9电子</div>
        <div>CQ9(9)%</div>
        <div style="color: red">0</div>
      </div>
      <div class="item">
        <div>CQ9电子</div>
        <div>CQ9(9)%</div>
        <div style="color: red">0</div>
      </div>
      <div class="item">
        <div>CQ9电子</div>
        <div>CQ9(9)%</div>
        <div style="color: red">0</div>
      </div>
      <div class="item">
        <div>CQ9电子</div>
        <div>CQ9(9)%</div>
        <div style="color: red">0</div>
      </div>
      <div class="item">
        <div>CQ9电子</div>
        <div>CQ9(9)%</div>
        <div style="color: red">0</div>
      </div>
      <div class="item">
        <div>CQ9电子</div>
        <div>CQ9(9)%</div>
        <div style="color: red">0</div>
      </div>
      <div class="item">
        <div>CQ9电子</div>
        <div>CQ9(9)%</div>
        <div style="color: red">0</div>
      </div>
      <div class="item">
        <div>CQ9电子</div>
        <div>CQ9(9)%</div>
        <div style="color: red">0</div>
      </div>
      <div class="item">
        <div>CQ9电子</div>
        <div>CQ9(9)%</div>
        <div style="color: red">0</div>
      </div>
      <div class="item">
        <div>CQ9电子</div>
        <div>CQ9(9)%</div>
        <div style="color: red">0</div>
      </div>
      <div class="item">
        <div>CQ9电子</div>
        <div>CQ9(9)%</div>
        <div style="color: red">0</div>
      </div>
      <div class="item">
        <div>CQ9电子</div>
        <div>CQ9(9)%</div>
        <div style="color: red">0</div>
      </div>
      <div class="item">
        <div>CQ9电子</div>
        <div>CQ9(9)%</div>
        <div style="color: red">0</div>
      </div>
      <div class="item">
        <div>CQ9电子</div>
        <div>CQ9(9)%</div>
        <div style="color: red">0</div>
      </div>
      <div class="item">
        <div>CQ9电子</div>
        <div>CQ9(9)%</div>
        <div style="color: red">0</div>
      </div>
      <div class="item">
        <div>CQ9电子</div>
        <div>CQ9(9)%</div>
        <div style="color: red">0</div>
      </div>
      <div class="item">
        <div>CQ9电子</div>
        <div>CQ9(9)%</div>
        <div style="color: red">0</div>
      </div>
      <div class="item">
        <div>CQ9电子</div>
        <div>CQ9(9)%</div>
        <div style="color: red">0</div>
      </div>
      <div class="item">
        <div>CQ9电子</div>
        <div>CQ9(9)%</div>
        <div style="color: red">0</div>
      </div>
      <div class="item">
        <div>CQ9电子</div>
        <div>CQ9(9)%</div>
        <div style="color: red">0</div>
      </div>
      <div class="item">
        <div>CQ9电子</div>
        <div>CQ9(9)%</div>
        <div style="color: red">0</div>
      </div>
      <div class="item">
        <div>CQ9电子</div>
        <div>CQ9(9)%</div>
        <div style="color: red">0</div>
      </div>
      <div class="item">
        <div>CQ9电子</div>
        <div>CQ9(9)%</div>
        <div style="color: red">0</div>
      </div>
      <div class="item">
        <div>CQ9电子</div>
        <div>CQ9(9)%</div>
        <div style="color: red">0</div>
      </div>
      <div class="item">
        <div>CQ9电子</div>
        <div>CQ9(9)%</div>
        <div style="color: red">0</div>
      </div>
      <div class="item">
        <div>CQ9电子</div>
        <div>CQ9(9)%</div>
        <div style="color: red">0</div>
      </div>
      <div class="item">
        <div>CQ9电子</div>
        <div>CQ9(9)%</div>
        <div style="color: red">0</div>
      </div>
      <div class="item">
        <div>CQ9电子</div>
        <div>CQ9(9)%</div>
        <div style="color: red">0</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LoginPage"
}
</script>

<style scoped lang="less">
.app-container {
  padding: 20px;
  .account-info {
    .item {
      margin-bottom: 20px;
    }
  }
  .noticeHeader {
    display: flex;
    flex: 1;
    justify-content: space-between;
    font-size: 14px;
    .noticeHeaderLeft {
      color: #fa3c3c;
    }
    .noticeHeaderRight {
      color: rgb(141, 141, 141);
    }
  }
  .games {
    display: flex;
    flex-wrap: wrap;
    .item {
      width: calc(20% - 16px);
      font-weight: 700;
      border: 1px solid #e3e3e3;
      background-color: #fff;
      padding: 10px;
      margin: 8px;
      text-align: center;
      font-size: 18px;
      -webkit-box-flex: 0;
      -ms-flex-positive: 0;
      flex-grow: 0;
      box-sizing: border-box;
    }
  }
  ::v-deep .el-card__body {
    height: 250px;
    padding: 15px 20px 20px 20px;
  }
  ::v-deep .el-collapse {
    border-top: 0;
    border-bottom: 0;
  }
  ::v-deep .el-collapse-item__header {
    border-bottom: 0;
    height: 38px;
    line-height: 38px;
  }
}
</style>
