<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>白名单管理</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="app-container home">
      <el-alert
          :closable="false"
          type="warning"
          title="格式: 127.0.0.33|127.0.0.99，多个请以符号 | 区分"
      ></el-alert>

      <el-form ref="form" :model="form" label-width="120px" :rules="rules" style="margin-top: 25px;">
        <el-form-item label="商户白名单IP" prop="acountIp">
          <el-input type="textarea" :rows="4" v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="商户白名单IP" prop="apiIp">
          <el-input type="textarea" :rows="4" v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="" prop="apiIp">
          <el-button type="primary">更新</el-button>
        </el-form-item>
      </el-form>

      <div class="czsm">
        <span style="margin-left: 30px;">说明和方法</span>
        <el-divider></el-divider>
        <div style="margin-left: 30px;">
          <p style="color: red">温馨提示：API白名单，需要把公网ipv4和ipv6都填写上</p>
          <h2>IPv4查看方法</h2>
          <p>请登录您服务器供应商的后台，查看服务器的公网ip</p>
          <h2>IPv6查看方法</h2>
          <p>1、如果windows服务器：控制台输入ipconfig</p>
          <p>2、如果linux 服务器：shell命令输入ifconfig</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "whitePage",
  data () {
    return {
      form: {
        acountIp: '',
        apiIp: ''
      },
      rules: {
        acountIp: [
          { required: true, message: '请输入', trigger: 'blur' }
        ],
        apiIp: [
          { required: true, message: '请输入', trigger: 'blur' }
        ],
      }
    }
  }
}
</script>

<style scoped lang="less">
.app-container {
  .czsm {
    margin-top: 90px;
    span {
      font-size: 18px;
    }
    p {
      font-size: 12px;
    }
  }
}
</style>
