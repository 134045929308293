<template>
  <el-container class="home-container">
    <!-- 头部区域 -->
    <el-header>
      <div style="padding-left: 10px;">
        <img src="../assets/logo.png" style="width: 32px;height: 32px;" alt="">
        <span style="font-size: 16px;font-weight: 600;">商户后台管理系统 </span>
      </div>
<!--      <el-button type="primary" @click="logout" size="mini">退出</el-button>-->
      <el-dropdown trigger="click" style="margin-right: 30px;" @command="commandHandler">
        <div class="block"><el-avatar shape="square" :size="40" :src="squareUrl"></el-avatar></div>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="logout">退出登录</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </el-header>
    <!-- 页面主体区域 -->
    <el-container>
      <!-- 侧边栏 -->
      <el-aside :width="isCollapse ? '64px' : '200px'">
<!--        <div class="toggle-button" @click="toggleCollapse">|||</div>-->
        <i class="toggle-button el-icon-s-unfold" @click="toggleCollapse" v-if="isCollapse"></i>
        <i class="toggle-button el-icon-s-fold" @click="toggleCollapse" v-else></i>
        <!-- 侧边栏菜单区域 -->
        <el-menu background-color="#314152" text-color="#fff" active-text-color="#409EFF" unique-opened :collapse="isCollapse" :collapse-transition="false" router :default-active="activePath">
          <!-- 一级菜单 -->
          <div  v-for="item in menulist" :key="item.id">
            <el-submenu :index="item.id + ''" v-if="item.children">
              <!-- 一级菜单的模板区域 -->
              <template slot="title" v-if="item.children && item.children.length > 0">
                <!-- 图标 -->
                <i :class="iconsObj[item.id]"></i>
                <!-- 文本 -->
                <span v-if="!isCollapse">{{item.authName}}</span>
              </template>



              <!-- 根据是否有二级菜单进行渲染 -->
              <template v-if="item.children && item.children.length > 0">
                <!-- 渲染二级菜单 -->
                <el-menu-item :index="subItem.path" v-for="subItem in item.children" :key="subItem.id" @click="saveNavState(subItem.path)">
                  <!-- 二级菜单的标题 -->
                  <template slot="title">
                    <!-- 图标 -->
                    <i class="el-icon-menu"></i>
                    <!-- 文本 -->
                    <span>{{subItem.authName}}</span>
                  </template>
                </el-menu-item>
              </template>
            </el-submenu>

            <template v-else>
              <el-menu-item :index="item.path"  @click="saveNavState(item.path)">
                <i :class="iconsObj[item.id]" v-if="isCollapse"></i>
                <!-- 二级菜单的标题 -->
                <template slot="title">
                  <!-- 图标 -->
                  <i :class="iconsObj[item.id]"></i>
                  <!-- 文本 -->
                  <span>{{item.authName}}</span>
                </template>
              </el-menu-item>
            </template>
          </div>

        </el-menu>
      </el-aside>
      <!-- 右侧内容主体 -->
      <el-main>
        <!-- 路由占位符 -->
        <router-view></router-view>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import menu from "@/utils/menu";
export default {
  name: 'HomePage',
  data() {
    return {
      squareUrl: require('@/assets/images/avatar.png'),
      // 左侧菜单数据
      menulist: [],
      iconsObj: {
        '1': 'el-icon-tickets',
        '2': 'el-icon-s-cooperation',
        '3': 'el-icon-wallet',
        '4': 'el-icon-trophy',
        '5': 'el-icon-tickets',
        '6': 'el-icon-phone',
        '7': 'el-icon-setting'
      },
      // 是否折叠
      isCollapse: false,
      // 被激活的链接地址
      activePath: ''
    }
  },
  created() {
    this.getMenuList()
    this.activePath = window.sessionStorage.getItem('activePath')
  },
  methods: {
    commandHandler (e) {
      if (e == 'logout') {
        this.logout()
      }
    },
    logout() {
      window.sessionStorage.clear()
      this.$router.push('/login')
    },
    // 获取所有的菜单
    async getMenuList() {
      // const { data: res } = await this.$http.get('menus')
      // if (res.meta.status !== 200) return this.$message.error(res.meta.msg)
      // this.menulist = res.data
      // console.log(res)
      console.log('menumenumenu', menu)
      this.menulist = menu
    },
    // 点击按钮，切换菜单的折叠与展开
    toggleCollapse() {
      this.isCollapse = !this.isCollapse
    },
    // 保存链接的激活状态
    saveNavState(activePath) {
      window.sessionStorage.setItem('activePath', activePath)
      this.activePath = activePath
    }
  }
}
</script>

<style lang="less" scoped>
.home-container {
  height: 100%;
}
.el-header {
  background-color: #373d41;
  display: flex;
  justify-content: space-between;
  padding-left: 0;
  align-items: center;
  color: #fff;
  font-size: 20px;
  > div {
    display: flex;
    align-items: center;
    span {
      margin-left: 15px;
    }
  }
}

.el-aside {
  background-color: #314152;
  .el-menu {
    border-right: none;
  }
}

.el-main {
  //background-color: #eaedf1;
}

.iconfont {
  margin-right: 10px;
}

.toggle-button {
  width: 100%;
  background-color: #4a5064;
  font-size: 22px;
  line-height: 24px;
  color: #fff;
  text-align: center;
  letter-spacing: 0.2em;
  cursor: pointer;
}
</style>
