import request from "./http.js";
let apiFun = {};

apiFun.register = (params) => request("post", "register", params);
apiFun.login = (params) => request("post", "login", params);

apiFun.userList = (params) => request("get", "user/list", params);
apiFun.userEdit = (params) => request("post", `user/edit/${params._id}`, params);
apiFun.userDelete = (params) => request("get", `user/delete/${params}`);

apiFun.gameTypeList = (params) => request("get", "gameType/list", params);
apiFun.gameTypeAdd = (params) => request("post", `gameType/add`, params);
apiFun.gameTypeEdit = (params) => request("post", `gameType/edit/${params._id}`, params);
apiFun.gameTypeDelete = (params) => request("get", `gameType/delete/${params}`);

apiFun.gameList = (params) => request("get", "game/list", params);
apiFun.gameAdd = (params) => request("post", `game/add`, params);
apiFun.gameEdit = (params) => request("post", `game/edit/${params._id}`, params);
apiFun.gameDelete = (params) => request("get", `game/delete/${params}`);

//暴露对象
export default apiFun;
