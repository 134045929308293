<template>
  <div>
    <!-- 面包屑导航区域 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>游戏管理</el-breadcrumb-item>
      <el-breadcrumb-item>转账记录</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 卡片视图区域 -->
    <el-card>
      <!-- 搜索与添加区域 -->
      <el-row :gutter="20">
        <el-form :inline="true" :model="queryInfo" size="small" class="demo-form-inline">
          <el-form-item label="单号">
            <el-input v-model="queryInfo.account" placeholder="请输入内容"></el-input>
          </el-form-item>
          <el-form-item label="会员账号">
            <el-input v-model="queryInfo.yxaccount" placeholder="请输入内容"></el-input>
          </el-form-item>
          <el-form-item label="游戏代码">
            <el-input v-model="queryInfo.name" placeholder="请输入内容"></el-input>
          </el-form-item>
          <el-form-item label="转账类型">
            <el-select v-model="queryInfo.status" placeholder="请选择">
              <el-option
                  v-for="item in typeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="订单状态">
            <el-select v-model="queryInfo.status" placeholder="请选择">
              <el-option
                  v-for="item in statusList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" icon="el-icon-search" @click="getUserList">搜索</el-button>
            <el-button @click="getUserList" icon="el-icon-refresh">重置</el-button>
          </el-form-item>
        </el-form>
      </el-row>

      <!-- 用户列表区域 -->
      <el-table :data="userlist" border stripe>
        <el-table-column type="selection" width="55">
        </el-table-column>
        <el-table-column label="订单号" prop="account"></el-table-column>
        <el-table-column label="会员账号" prop="username"></el-table-column>
        <el-table-column label="所属商户" prop="qq"></el-table-column>
        <el-table-column label="转账类型" prop="qq"></el-table-column>
        <el-table-column label="接口代码" prop="email"></el-table-column>
        <el-table-column label="转账金额" prop="email"></el-table-column>
        <el-table-column label="转账前余额" prop="email"></el-table-column>
        <el-table-column label="转账后余额" prop="email"></el-table-column>
        <el-table-column label="状态" prop="email"></el-table-column>
        <el-table-column label="时间" prop="email"></el-table-column>
      </el-table>

      <!-- 分页区域 -->
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="queryInfo.pageNum" :page-sizes="[1, 2, 5, 10]" :page-size="queryInfo.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total">
      </el-pagination>
    </el-card>


  </div>
</template>

<script>
export default {
  name: 'useraPage',
  data() {
    return {
      editForm: {},
      dialogList: [],
      typeList: [
        { label: '转入', value: 0 },
        { label: '转出', value: 1 },
      ],
      statusList: [
        { label: '转账超时', value: 0 },
        { label: '发生未知错误', value: 1 },
        { label: '转账失败', value: 2 },
        { label: '转账失败游戏维护中', value: 3 },
        { label: '成功', value: 4 },
      ],
      // 获取用户列表的参数对象
      queryInfo: {
        // 当前的页数
        pageNum: 1,
        // 当前每页显示多少条数据
        pageSize: 10
      },
      userlist: [],
      total: 0,
      editDialogVisible: false,
    }
  },
  mounted() {
    // this.getUserList()
  },
  methods: {
    async getUserList() {
      const res = await this.$apiFun.userList(this.queryInfo)
      this.userlist = res.data
      this.total = res.total
      console.log(res, 'dataList')
    },
    // 监听 pageSize 改变的事件
    handleSizeChange(newSize) {
      // console.log(newSize)
      this.queryInfo.pageSize = newSize
      this.getUserList()
    },
    // 监听 页码值 改变的事件
    handleCurrentChange(newPage) {
      console.log(newPage)
      this.queryInfo.pageNum = newPage
      this.getUserList()
    },
  }
}
</script>

<style lang="less" scoped>
</style>
